@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,400;0,500;0,600;0,700;1,400;1,500;1,600;1,700&display=swap')
@import "./spacings"
  
$body-background: #353b48
$content-background: #f5f6fa
$font-family: Montserrat, Arial

body, html
  height: 100%
 
body
  background-color: $body-background
  font-family: 'Montserrat' !important

.ant-layout
  min-height: 100vh
  background-color: $content-background

.ant-layout-content
  padding-bottom: 50px !important

.ant-layout-footer
  background-color: $body-background
  color: white

.disabled-row
  background-color: #eee
  pointer-events: none

.container
  display: flex
  align-items: center
  justify-content: center
  height: 100%

.is-muted
  color: #d6d6d6

.is-bold
  font-weight: 700 !important

.ant-table-thead
  tr 
    background: #F9F9F9
    border-radius: 8px 0px 0px 0px !important

.ant-table-cell     
  padding: 20px !important

th.ant-table-cell
  text-transform: uppercase
  background-color: #F9F9F9 !important
  color: #272727 !important

td.ant-table-cell
  color: #666464 !important

.ant-table-thead > tr > th::before
  position: relative !important
  width: 0 !important
  height: 0 !important

.ant-btn
  border-radius: 5px 

.ant-btn-primary
  background-color: #FF9000 !important
  border-color: #FF9000 !important

.ant-btn-danger
  color: #ff4d4f !important
  border-color: #ff4d4f !important#ff4d4f
  background-color: #fff !important

.ant-table-tbody > tr > td
  border-bottom: 2px solid #f0f0f0

.ant-checkbox-inner
  border-color: #000 !important

.ant-tag-success
  border-color: #52c41a !important

.w-full
  width: 100%

.flex
  display: flex

.justify-content-center
  justify-content: center

.ml-auto
  margin-left: auto

.is-italic
  font-style: italic

.aktiva-ant-modal-footer
  display: flex
  .ant-form-actions-wrapper
    margin-left: auto

.ant-page-header.ant-page-header-ghost
  padding-left: 0
  padding-right: 0  


.ellipsis-container
  height: 30px
  width: 30px
  border-radius: 30px
  display: inline-flex
  align-items: center
  justify-content: center
  cursor: pointer
  margin-right: 5px
  background-color: #fff !important
  &.background-initial
    background-color: initial !important

.th.ant-table-cell
  padding: 20px
  background-color: red

.ant-form-imports-filters
  .ant-input-group
    .ant-row
      display: flex
      vertical-align: middle
      align-items: baseline
      justify-content: space-between 