// $jet: #2eb3a9

.contentWrapper
  display: flex
  width: 100%
  flex-direction: column
  justify-content: center
  align-items: center
  img.logo
    margin-bottom: 10px
    // display: flex


// .antLayoutWrapper
//   display: flex
//   background: #FFFFFF
//   height: 100%
//   \:global
//     .ant-menu-title-content
//       color: $jet
//     .ant-menu-submenu-active::after
//       display: none


// .headerWrapper
//   height: 80px
//   display: flex
//   width: 100%
//   align-items: center
//   margin-bottom: 10px
//   box-shadow: 6px 0 9px 4px #cacaca
//   background-color: #FFFFFF
//   position: fixed
//   z-index: 9
//   background: #FFFFFF
//   left: 0

// .logo
//   float: left
//   display: flex
//   align-items: center
//   height: 64px
//   img
//     height: 50px


// .header
//   position: fixed
//   z-index: 1
//   width: 100%
//   background-color: #FFF
//   box-shadow: 0px 4px 36px 0px #8787871F

  
