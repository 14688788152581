// $body-color: #f2f5f5;

// h1, p {
//   color: white !important;
// }

// input[type=submit] {
//   border: 0;
// }

footer
  display: none

// .ant-btn {
//   display: flex;
//   flex-direction: row;
//   justify-content: center;
//   align-items: center;
//   padding: 8px 12px;
//   border-radius: 60px !important;
//   color: #fff !important;
//   background-color: #F79622 !important;
//   border-color: #F79622 !important;
//   font-weight: bold !important;
// }

// .outlined-btn {
//   background-color: #F2F5F5 !important;
//   color: #F79622 !important;
//   font-weight: bold !important;
//   border: solid 1px !important;
// }

// .outlined-home-btn {
//   background-color: transparent !important;
//   color: #F79622 !important;
//   font-weight: bold !important;
//   border: solid 1px !important;
// }

.home-container
  width: 100%

// .signin-wrapper {
//   display: flex;
//   flex-direction: column;
//   justify-content: center;
//   align-items: center;
//   > img {
//     width: 150px;
//     margin: 20px 0;
//   }
// }

// .text-container {
//   margin-left: 152px;
//   margin-top: 102px;
//   max-width: 600px;
// }

// .wrapper {
//   img {
//     margin-left: auto;
//     margin-right: auto;
//     display: block;
//   }
//   #logo2 {
//     margin-top: 16px;
//   }
// }

// label {
//   font-size: 14px !important;
//   font-weight: 500;
//   line-height: 1.29;
//   letter-spacing: normal;
// }

// .ant-alert-info {
//   background-color: #e6f7ff;
//   border: 1px solid #1890ff;
// }

// .ant-alert-danger {
//   background-color: #fffbe6;
//   border: 1px solid #ffe58f;
// }

// .ant-select {
//   width: 100%;
// }

// .ant-select-selector {
//   border-style: none !important;
// }

// .ant-form-item-explain-error {
//   color: red !important;
// }

// input[error] {
//   border-color: red !important;
// }

// .ant-card-bordered {
//   height: 100vh;
//   background-color: $body-color !important;
// }

// .ant-drawer-body, .ant-drawer-header {
//   background-color: #F2F5F5 !important;
// }

// .ant-input:not(.ant-input-status-error) {
//   border-style: none !important;
// }

// .ant-input-password {
//   border-style: none !important;
// }

// .wrapped-content {
//   padding-left: 120px;
//   padding-right: 120px;
// }

// .gem-react-select__control {
//   border-style: none !important;
//   .gem-react-select__placeholder {
//     color: #E6E6E6 !important;
//   }
// }

// .ant-form-item-control {
//   ::placeholder {
//     color: #E6E6E6 !important;
//   }
// }